import React from 'react';
import Home from './Home';
import Navbar from '../components/navbar/Navbar';

const LoginPage = () => {
    return (
        <>
            <Navbar isLoggedIn={true} />
            <Home />
        </>
    )
}

export default LoginPage;
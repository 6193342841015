// Common or dashboard
export const SET_IS_LOADING = "SET_IS_LOADING";
export const SET_IS_SIDEBAR_OPEN = "SET_IS_SIDEBAR_OPEN";

// Login Api
export const ON_CUSTOMER_LOGIN = "ON_CUSTOMER_LOGIN";
export const OPEN_OTP_MODAL = 'OPEN_OTP_MODAL';
export const CLOSE_OTP_MODAL = 'CLOSE_OTP_MODAL';
export const ON_OTP_VERIFICATION = 'ON_OTP_VERIFICATION';
export const SET_CUSTOMER_DATA = 'SET_CUSTOMER_DATA';
export const OTP_SUCCESS = 'OTP_SUCCESS';
export const OTP_FAILURE = 'OTP_FAILURE';
export const SET_IS_LOGGED = 'SET_IS_LOGGED';

//Review
export const GET_ASTROLOGERS_REVIEWS = "GET_ASTROLOGERS_REVIEWS";
export const SET_ASTROLOGERS_REVIEWS = "SET_ASTROLOGERS_REVIEWS";
export const GET_APP_REVIEWS = "GET_APP_REVIEWS";
export const SET_APP_REVIEWS = "SET_APP_REVIEWS";
export const UPDATE_ASTROLOER_REVIEW_STATUS = "UPDATE_ASTROLOER_REVIEW_STATUS";
export const DELETE_ASTROLOGER_REVIEW = "DELETE_ASTROLOGER_REVIEW";

//Customer
export const GET_ALL_CUSTOMER = "GET_ALL_CUSTOMER";
export const SET_ALL_CUSTOMER = "SET_ALL_CUSTOMER";
export const DELETE_CUSTOMER = "DELETE_CUSTOMER";
export const BAN_CUSTOMER = "BAN_CUSTOMER";
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";

//Astrologer
export const GET_ALL_ASTROLOGER = "GET_ALL_ASTROLOGER";
export const SET_ALL_ASTROLOGER = "SET_ALL_ASTROLOGER";
export const UPDATE_ASTROLOGER_CHAT_STATUS = "UPDATE_ASTROLOGER_CHAT_STATUS";
export const UPDATE_ASTROLOER_CALL_STATUS = "UPDATE_ASTROLOER_CALL_STATUS";
export const GET_ENQUIRY_ASTROLOGERS = "GET_ENQUIRY_ASTROLOGERS";
export const SET_ENQUIRY_ASTROLOGERS = "SET_ENQUIRY_ASTROLOGERS";
export const UPDATE_ENQUIRY_STATUS = "UPDATE_ENQUIRY_STATUS";
export const UPDATE_ASTROLOGER_DATA = "UPDATE_ASTROLOGER_DATA";
export const ADD_ASTROLOGER = "ADD_ASTROLOGER";
export const VERIFY_UNVERIFY_ASTROLOGER = "VERIFY_UNVERIFY_ASTROLOGER";
export const DELETE_ASTROLOGER = "DELETE_ASTROLOGER";

//Experties
export const GET_ALL_EXPERTIES = "GET_ALL_EXPERTIES";
export const GET_ALL_MAIN_EXPERTIES = "GET_ALL_MAIN_EXPERTIES";
export const SET_ALL_EXPERTIES = "SET_ALL_EXPERTIES";
export const SET_ALL_MAIN_EXPERTIES = "SET_ALL_MAIN_EXPERTIES";
export const CREATE_EXPERTIES = "CREATE_EXPERTIES";
export const CREATE_MAIN_EXPERTIES = "CREATE_MAIN_EXPERTIES";
export const UPDATE_EXPERTIES = "UPDATE_EXPERTIES";
export const UPDATE_MAIN_EXPERTIES = "UPDATE_MAIN_EXPERTIES";
export const DELETE_EXPERTIES = "DELETE_EXPERTIES";
export const DELETE_MAIN_EXPERTIES = "DELETE_MAIN_EXPERTIES";

//Skills
export const CREATE_SKILL = "CREATE_SKILL";
export const GET_ALL_SKILLS = "GET_ALL_SKILLS";
export const SET_ALL_SKILLS = "SET_ALL_SKILLS";
export const UPDATE_SKILL = "UPDATE_SKILL";
export const DELETE_SKILL = "DELETE_SKILL";

export const CREATE_SUB_SKILL = "CREATE_SUB_SKILL";
export const GET_ALL_SUB_SKILLS = "GET_ALL_SUB_SKILLS";
export const SET_ALL_SUB_SKILLS = "SET_ALL_SUB_SKILLS";
export const UPDATE_SUB_SKILL = "UPDATE_SUB_SKILL";
export const DELETE_SUB_SKILL = "DELETE_SUB_SKILL";

//Remedies
export const CREATE_REMEDY = "CREATE_REMEDY";
export const GET_ALL_REMEDIES = "GET_ALL_REMEDIES";
export const SET_ALL_REMEDIES = "SET_ALL_REMEDIES";
export const UPDATE_REMEDY = "UPDATE_REMEDY";
export const DELETE_REMEDY = "DELETE_REMEDY";

//Banners
export const UPLOAD_APP_BANNER = "UPLOAD_APP_BANNER";
export const UPLOAD_WEB_BANNER = "UPLOAD_WEB_BANNER";
export const GET_APP_BANNERS = "GET_APP_BANNERS";
export const SET_APP_BANNERS = "SET_APP_BANNERS";
export const DELETE_BANNERS = "DELETE_BANNERS";
export const EDIT_BANNERS = "EDIT_BANNERS";

//Notification
export const GET_CUSTOMER_NOTIFICATIONS = "GET_CUSTOMER_NOTIFICATIONS";
export const GET_ASTROLOGER_NOTIFICATIONS = "GET_ASTROLOGER_NOTIFICATIONS";
export const SET_CUSTOMER_NOTIFICATIONS = "GET_CUSTOMER_NOTIFICATIONS";
export const SET_ASTROLOGER_NOTIFICATIONS = "GET_ASTROLOGER_NOTIFICATIONS";
export const SEND_CUSTOMER_NOTIFICATIONS = "SEND_CUSTOMER_NOTIFICATIONS";
export const SEND_ASTROLOGER_NOTIFICATIONS = "SEND_ASTROLOGER_NOTIFICATIONS";
export const DELETE_CUSTOMER_NOTIFICATIONS = "GET_CUSTOMER_NOTIFICATIONS";
export const DELETE_ASTROLOGER_NOTIFICATIONS = "GET_ASTROLOGER_NOTIFICATIONS";

//History
export const GET_CHAT_HISTORY = "GET_CHAT_HISTORY";
export const SET_CHAT_HISTORY = "SET_CHAT_HISTORY";
export const GET_CHAT_SUMMARY = "GET_CHAT_SUMMARY";
export const SET_CHAT_SUMMARY = "SET_CHAT_SUMMARY";
export const GET_CALL_HISTORY = "GET_CALL_HISTORY";
export const SET_CALL_HISTORY = "SET_CALL_HISTORY";
export const SET_CUSTOMER_FIREBASE_ID = "SET_CUSTOMER_FIREBASE_ID";

//Dashboard
export const GET_DASHBOARD = "GET_DASHBOARD";
export const SET_DASHBOARD = "SET_DASHBOARD";

//Reports
export const GET_ADMIN_EARNINGS = "GET_ADMIN_EARNINGS";
export const SET_ADMIN_EARNINGS = "SET_ADMIN_EARNINGS";
export const GET_RECIEPT_SUMMARY = "GET_RECIEPT_SUMMARY";
export const SET_RECIEPT_SUMMARY = "SET_RECIEPT_SUMMARY";

//Language
export const CREATE_LANGUAGE = "CREATE_LANGUAGE";
export const GET_ALL_LANGUAGE = "GET_ALL_LANGUAGE";
export const SET_ALL_LANGUAGE = "SET_ALL_LANGUAGE";
export const UPDATE_LANGUAGE = "UPDATE_LANGUAGE";
export const DELETE_LANGUAGE = "DELETE_LANGUAGE";

//Country
export const GET_ALL_COUNTRY = "GET_ALL_COUNTRY";
export const SET_ALL_COUNTRY = "SET_ALL_COUNTRY";

//Recharge Plan

export const CREATE_RECHARGE_PLAN = "CREATE_RECHARGE_PLAN";
export const GET_RECHARGE_PLAN = "GET_RECHARGE_PLAN";
export const SET_RECHARGE_PLAN = "SET_RECHARGE_PLAN";
export const UPDATE_RECHARGE_PLAN = "UPDATE_RECHARGE_PLAN";
export const DELETE_RECHARGE_PLAN = "DELETE_RECHARGE_PLAN";
export const UPDATE_RECHARGE_PLAN_STATUS = "UPDATE_RECHARGE_PLAN_STATUS";

//FirstRecharge Offer
export const CREATE_FIRST_RECHARGE_OFFER = "CREATE_FIRST_RECHARGE_OFFER";
export const GET_FIRST_RECHARGE_OFFER = "GET_FIRST_RECHARGE_OFFER";
export const SET_FIRST_RECHARGE_OFFER = "SET_FIRST_RECHARGE_OFFER";
export const UPDATE_FIRST_RECHARGE_OFFER = "UPDATE_FIRST_RECHARGE_OFFER";
export const UPDATE_FIRST_RECHARGE_OFFER_STATUS =  "UPDATE_FIRST_RECHARGE_OFFER_STATUS";
export const DELETE_FIRST_RECHARGE_OFFER = "DELETE_FIRST_RECHARGE_OFFER";

//course
export const GET_COURSE_BANNER = "GET_COURSE_BANNER"
export const GET_COURSES_LIST = "GET_COURSES_LIST"
export const GET_LIVE_CLASS = "GET_LIVE_CLASS"
export const SET_LIVE_CLASS = "SET_LIVE_CLASS"
export const GET_DEMO_CLASS = "GET_DEMO_CLASS"
export const SET_DEMO_CLASS = "SET_DEMO_CLASS"
export const GET_DEMO_CLASS_BY_ID = "GET_DEMO_CLASS_BY_ID"
export const SET_DEMO_CLASS_BY_ID = "SET_DEMO_CLASS_BY_ID"
export const GET_LIVE_CLASS_DETAILS = "GET_LIVE_CLASS_DETAILS"
export const SET_LIVE_CLASS_DETAILS = "SET_LIVE_CLASS_DETAILS"
export const GET_CLASS_BY_LIVE_CLASS_ID = "GET_CLASS_BY_LIVE_CLASS_ID"
export const SET_CLASS_BY_LIVE_CLASS_ID = "SET_CLASS_BY_LIVE_CLASS_ID"
export const GET_WORKSHOP = "GET_WORKSHOP"
export const GET_WORKSHOP_WITHOUT_ID = "GET_WORKSHOP_WITHOUT_ID"
export const GET_TEACHERS_LIST = "GET_TEACHERS_LIST"
export const BOOKED_DEMO_CLASS = "BOOKED_DEMO_CLASS"
export const LIVE_CLASS_OF_CLASS = "LIVE_CLASS_OF_CLASS"
export const CHECK_CUSTOMER_DEMO_CLASS_BOOKED = "CHECK_CUSTOMER_DEMO_CLASS_BOOKED"
export const GET_ALL_DEMO_CLASSS = "GET_ALL_DEMO_CLASSS"
export const REGISTER_FOR_LIVE_CLASS = "REGISTER_FOR_LIVE_CLASS"
export const IS_REGISTER_FOR_LIVE_CLASS = "IS_REGISTER_FOR_LIVE_CLASS"
export const ON_BUY_LIVE_CLASS = "ON_BUY_LIVE_CLASS"
export const ON_REGISTERED_FOR_LIVE_CLASS = "ON_REGISTERED_FOR_LIVE_CLASS"
export const GET_SINGLE_DEMO_CLASS = "GET_SINGLE_DEMO_CLASS"
export const GET_SINGLE_LIVE_CLASS = "GET_SINGLE_LIVE_CLASS"
export const GET_REGISTERED_LIVE_CLASS = "GET_REGISTERED_LIVE_CLASS"
export const GET_CURRENT_LIVE_COURSE_HISTORY = "GET_CURRENT_LIVE_COURSE_HISTORY"
export const GET_COMPLETED_LIVE_COURSE_HISTORY = "GET_COMPLETED_LIVE_COURSE_HISTORY"
export const GET_COURSE_HISTORY = "GET_COURSE_HISTORY"
export const GET_INITIATED_PAYMENT_OF_LIVE_CLASS = "GET_INITIATED_PAYMENT_OF_LIVE_CLASS"
export const SET_INITIATED_PAYMENT_OF_LIVE_CLASS = "SET_INITIATED_PAYMENT_OF_LIVE_CLASS"
export const GET_STATUR_FOR_REGISTERED_DEMO = "GET_STATUR_FOR_REGISTERED_DEMO"
export const SET_STATUR_FOR_REGISTERED_DEMO = "SET_STATUR_FOR_REGISTERED_DEMO"

// Testimonials
export const GET_TESTIMONIALS = "GET_TESTIMONIALS"
export const SET_TESTIMONIALS = "SET_TESTIMONIALS"

export const REGISTER_LIVE_CLASS_FOR_WEB = "REGISTER_LIVE_CLASS_FOR_WEB"
export const REGISTER_DEMO_CLASS_FOR_WEB = "REGISTER_DEMO_CLASS_FOR_WEB"

// Fortune Talk 
export const GET_PRODUCT_CATEGORY_LIST = "GET_PRODUCT_CATEGORY_LIST"
export const SET_PRODUCT_CATEGORY_LIST = "SET_PRODUCT_CATEGORY_LIST"
export const GET_PRODUCT_ALL_CATEGORY_LIST = "GET_PRODUCT_ALL_CATEGORY_LIST"
export const SET_PRODUCT_ALL_CATEGORY_LIST = "SET_PRODUCT_ALL_CATEGORY_LIST"

// Blogs list Displaying
export const GET_BLOG_LIST = "GET_BLOG_LIST"
export const SET_BLOG_LIST = "SET_BLOG_LIST"
export const FETCH_BLOGS_FAILURE = "FETCH_BLOGS_FAILURE";

//Third Party
export const HORO_CHART = "HORO_CHART";
export const SET_APP_HORO_CHART = "SET_APP_HORO_CHART";
export const FREE_INSIGHTS_BANNER = "FREE_INSIGHTS_BANNER";
export const API2_GET_DAILY_HOROSCOPE = "API2_GET_DAILY_HOROSCOPE";
